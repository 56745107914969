<template>
    <div class="mobilebodyarea">
         <section class="mobileNews">
            <div>
                <div v-if="loading" >
                    <div class="nl_loading">
                        <div class="nh_load"></div> 
                        <div class="nt_load"></div>
                        <div class="nd_load"> 
                            <ul class="nsl_load">
                                <li></li>
                                <li></li>
                            </ul> 
                            <p></p><p></p>
                            <p></p><p></p>
                            <p></p><p></p>
                            <p></p><p></p>
                        </div>
                    </div>
                </div>
                 <div class="newsLabel" v-if="Object.keys(showNews).length > 0"
                    :class="(Object.keys(showNews).length > 0) ? 'show' : ''">
                    <div class="newsheading">{{showNews.title}}</div> 
                    <img class="newsthumb" :src="showNews.image" alt="News" title="News Image" /> 
                        <div class="newsdescription"> 
                            <ul class="newssource">
                                <li><a class="green" href="javascript:void(0)">{{showNews.source}}</a></li>
                                <li>{{formatNow(showNews.published,'now',0)}}</li>
                            </ul> 
                            <p v-html="showNews.description"></p>
                        </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    //import bannerslider from "../../components/shared/banner_slider";
    import Loader from "../../components/shared/appLoader";
    import { useMainStore } from "@/store";
    import moment from "moment";
    export default {
        setup() {
            const store = useMainStore();
            return { store };
        },
        data() {
            return {
                showNews: {},
                loading: false,
            }
        },
        methods: {
            goBack(){
                // if(this.$router.options.history.state.back.split('?')[0] == '/all-news'){
                //    let path = this.$router.options.history.state.back.split('?')[0]
                //    let query = this.$router.options.history.state.back.split('?')[1]
                //    this.$router.replace({path : path,query:{type : query.split('&')[0].split('=')[1],news:query.split('&')[1].split('=')[1],from:'back',id:this.$route.params.id}})
                // }else{
                // }
                    this.$router.back()
            },
            formatNow(date, format, offset) {
                if (!date) return 'N/A'
                let d = moment(date, 'YYYY-MM-DD HH:mm:ss')
                let tz = offset != 0 ? offset : -(new Date().getTimezoneOffset())
                d.add(tz, 'minutes')
                return d.fromNow()
            },
            calnewsDetail(id) {
                let form = {
                    '_id': id
                }
                this.store
                    .callNewsDetails(form, false)
                    .then((response) => {
                        this.showNews = response
                        this.loading = false
                    })
                    .catch(() => this.loading = false);
            },
        },
        created() {
            if (this.$route.params.id) {
                this.loading = true
                this.calnewsDetail(this.$route.params.id.split("&")[0]);
            }
        },
        components: {
            Loader,
        },
    }
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
    .mobilebodyarea{
        background:#FBFBFB;
        padding:0 24px; 
    } 
    .newsthumb{
        background-color:#BAC4D0;
        min-height: 190px;
        border-radius:14px;
        margin-bottom:16px;
        width:100%;
    } 
    .newsheading{
        padding-right: 10px;
        font-size: 22px;
        line-height:30px;
        letter-spacing: -1px;
        color: #303A48;
        font-family: 'Inter', sans-serif;
        font-weight:600;
        margin-bottom:20px !important;
    }
    .newsdescription,
    .newsdescription p{
        color: #535b62; 
        font-size:18px;
        line-height: 24px;
        letter-spacing: -0.3px;
    }
    .newssource{
        display:flex;
        justify-content:'space-between';
        align-items:'center';
    }
    .newssource li{
        margin-right:10px;
        font-size:14px;
    }


    /* loading */
    .nl_loading .nh_load,
    .nl_loading .nt_load,
   
    .nl_loading .nd_load,
    .nl_loading .nsl_load,
    .nl_loading .nd_load p{
        background:#F0F0F4;
        margin-bottom:20px;
    }
    .nl_loading .nh_load{
        background:#F0F0F4;
        height:20px;
    }
    .nl_loading .nt_load{
        background:#F0F0F4;
        height:200px;
    }
    .nl_loading .nd_load{
        background:#F0F0F4;
        height:20px;
    }
    .nl_loading .nd_load{
        background:#F0F0F4;
        height:20px;
    }
    .nl_loading .nsl_load{
        background:#F0F0F4;
        height:20px;
    }
    .nl_loading .nd_load p{
        background:#F0F0F4;
        height:20px;
    }

</style>